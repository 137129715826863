import { ApolloProvider } from "@apollo/client";
import { Alert, PopupComponent } from "@yumis-coconudge/enola-admin-component";
import { AlertProvider } from "@yumis-coconudge/enola-admin-component/dist/next";
import { PopupProvider } from "@yumis-coconudge/enola-admin-component/dist/next/popup";
import { useEffect, useMemo, useState } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import AppRouter from "./app-router";
import client from "./client";
import { AuthorizationContext } from "./core/context/authorization.context";
import { parseJwt } from "./core/function/parse-jwt";
import { theme } from "./core/theme";

const App = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const payload = useMemo(() => {
    if (accessToken == null) return { sub: "", name: "" };
    if (accessToken === "") return { sub: "", name: "" };
    const rawPayload = parseJwt<{ sub: string; name: string }>(accessToken);
    return {
      sub: rawPayload.sub,
      name: rawPayload.name,
    };
  }, [accessToken]);

  const authorizationContext = useMemo(
    () => ({ accessToken, setAccessToken, refreshToken, setRefreshToken, payload: payload }),
    [accessToken, payload, refreshToken],
  );

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    if (storedAccessToken == null || storedAccessToken === "null") {
      setAccessToken("");
    } else {
      setAccessToken(storedAccessToken);
    }

    const storedRefreshToken = localStorage.getItem("refresh_token");
    if (storedRefreshToken == null || storedRefreshToken === "null") {
      setRefreshToken("");
    } else {
      setRefreshToken(storedRefreshToken);
    }
  }, []);

  useEffect(() => {
    if (refreshToken !== null) {
      localStorage.setItem("refresh_token", refreshToken);
    }
  }, [refreshToken]);

  useEffect(() => {
    if (accessToken !== null) {
      localStorage.setItem("access_token", accessToken);
    }
    client.resetStore();
  }, [accessToken]);

  return (
    <AuthorizationContext.Provider value={authorizationContext}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <AppRouter />
          <Alert />
          <PopupComponent.PopupComponent />
          <AlertProvider />
          <PopupProvider />
        </ThemeProvider>
      </ApolloProvider>
    </AuthorizationContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
*, :root {
  font-family: 'Spoqa Han Sans Neo', sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
`;

export default App;
