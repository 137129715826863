import React from "react";

export interface AuthorizationContextType {
  accessToken: string | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  payload: {
    sub: string;
    name: string;
  };
}

export const AuthorizationContext = React.createContext<AuthorizationContextType>({
  accessToken: null,
  setAccessToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  payload: {
    sub: "",
    name: "",
  },
});
