import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    primary: {
      100: "#e0f5d4",
      300: "#c1ed8a",
      500: "#098256",
      600: "#0e5f00",
      700: "#1f4d17",
    },
    secondary: {
      100: "#cee7ff",
      300: "#c5e2ff",
      500: "#4aa8ff",
      600: "#0e6fc9",
      700: "#376794",
    },
    dark: {
      0: "#000000",
      200: "#222222",
      300: "#333333",
      350: "#363738",
      400: "#444444",
      500: "#555555",
      600: "#666666",
      800: "#888888",
    },
    light: {
      0: "#ffffff",
      50: "#f4f4f4",
      100: "#e2e2e2",
      200: "#dedede",
      500: "#cfcfcf",
      540: "#cacfd7",
      550: "#c8c8c8",
    },
    background: {
      0: "#ffffff",
      1: "#fcf8fc",
      2: "#f7f8fc",
      3: "#f6f7f8",
    },
    success: {
      500: "#4aa8ff",
      600: "#0e6fc9",
      700: "#186CBA",
    },
    warning: {
      500: "#ffca2c",
    },
    danger: {
      500: "#f96565",
      600: "#e04747",
    },
    info: {
      500: "#4aa8ff",
      600: "#0e6fc9",
    },
  },
};
