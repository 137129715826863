import styled from "styled-components";

export const NotFoundPage = () => {
  return (
    <Container>
      <Wrapper>
        <h2>존재하지 않은 페이지입니다.</h2>
        <br />
        <p>잘못된 주소를 사용하였는지 확인해주세요.</p>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 30px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;
