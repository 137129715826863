import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RootSpinner } from "@yumis-coconudge/enola-admin-component";
import { InfoPage, LoginPage, SignUpPage } from "./pages/login";
import { MainPage } from "./pages/main";
import { NotFoundPage } from "./pages/not-found";
import { BookDetailForWindow } from "./pages/book";
import { OrderStatusDetailPageForWindow } from "./pages/sales-management";
import { CalculationDetailForWindow } from "./pages/calculation";
import { TestPage } from "./pages/test";
function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/main" replace />} />
        <Route path="/test" element={<TestPage />} />
        <Route
          path="/login"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <LoginPage />
            </React.Suspense>
          }
        />
        <Route
          path="/sign-up"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path="/info"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <InfoPage />
            </React.Suspense>
          }
        />
        <Route
          path="/main"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <MainPage />
            </React.Suspense>
          }
        />
        <Route
          path="/main/:tabId"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <MainPage />
            </React.Suspense>
          }
        />
        <Route
          path="/detail/book/:bookId"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <BookDetailForWindow />
            </React.Suspense>
          }
        />
        <Route
          path="/detail/order/:orderId"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <OrderStatusDetailPageForWindow />
            </React.Suspense>
          }
        />
        <Route
          path="/detail/calculation/:calculationId"
          element={
            <React.Suspense fallback={<RootSpinner />}>
              <CalculationDetailForWindow />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
