import { useCallback, useEffect, useRef, useState } from "react";

export const TestPage = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const loremRef = useRef<HTMLParagraphElement>(null);
  const intervalRef = useRef<NodeJS.Timer | number>();

  const [fontSize, setFontSize] = useState("");
  const [pixelRatio, setPixelRatio] = useState("");
  const [resolution, setResolution] = useState("");
  const [appResolution, setAppResolution] = useState("");
  const [viewport, setViewport] = useState("");
  const [viewportRatio, setViewportRatio] = useState("");

  const handleResize = useCallback(() => {
    if (containerRef.current == null || loremRef.current == null) return;
    setFontSize(window.getComputedStyle(loremRef.current).getPropertyValue("font-size"));
    setPixelRatio((window.devicePixelRatio * 100).toLocaleString() + "%");
    setResolution(`${window.screen.width} x ${window.screen.height}`);
    setAppResolution(`${window.screen.availWidth} x ${window.screen.availHeight}`);
    setViewport(`${document.body.clientWidth} x ${document.body.clientHeight}`);
    setViewportRatio(
      `${((document.body.clientWidth / window.screen.width) * 100).toLocaleString()}% ${(
        (document.body.clientHeight / window.screen.height) *
        100
      ).toLocaleString()}%`,
    );
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    intervalRef.current = setInterval(handleResize, 1000);
    return () => clearInterval(intervalRef.current);
  });

  return (
    <div ref={containerRef} style={{ padding: "30px", lineHeight: "normal" }}>
      <p ref={loremRef} style={{ fontSize: "1rem" }}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos debitis qui ab, dolores accusantium quis
        rerum a optio, earum, nisi similique sed tenetur aspernatur? Fuga excepturi nam consequuntur eligendi
        doloremque.
        <br />
        이네들은 된 별이 봄이 이름자 별 가슴속에 거외다. 멀리 별 새워 버리었습니다. 이 가을 둘 라이너 불러 헤일 우는
        이국 청춘이 거외다. 어머니, 헤는 이네들은 벌레는 했던 아이들의 이 헤일 있습니다.
      </p>
      <br />
      <p>기본 텍스트 크기: {fontSize}</p>
      <p>확대: {pixelRatio}</p>
      <p>디스플레이 해상도: {resolution}</p>
      <p>브라우저 해상도: {appResolution}</p>
      <p>뷰포트 크기: {viewport}</p>
      <p>뷰포트 비율: {viewportRatio}</p>
      <br />
      <p>{navigator.userAgent}</p>
      <br />
      <b style={{ fontWeight: "bold" }}>
        브라우저 창을 최대화 하시고 화면 전체가 나오도록 캡쳐해주세요.
        <br />
        MacOS: <kbd>Command</kbd> + <kbd>Shift</kbd> + <kbd>3</kbd>
        <br />
        Windows: <kbd>Print Screen</kbd> 또는 <kbd>Windows</kbd> + <kbd>Shift</kbd> + <kbd>S</kbd> → 전체화면 캡처 선택
      </b>
    </div>
  );
};
