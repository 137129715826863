import { ApolloClient, createHttpLink, from, InMemoryCache, Observable } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RefreshTokenDocument } from "./generated/graphql";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, prevContext) => {
  const token = localStorage.getItem("access_token");
  if (token === "") {
    return prevContext;
  }

  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

function getNewToken() {
  return new Observable<string>(subscriber => {
    const refreshToken = localStorage.getItem("refresh_token");
    client
      .mutate({ mutation: RefreshTokenDocument, variables: { refreshToken: refreshToken } })
      .then(res => {
        localStorage.setItem("access_token", res.data.reissueToken.accessToken);
        localStorage.setItem("refresh_token", res.data.reissueToken.refreshToken);
        subscriber.next(res.data.reissueToken.accessToken);
        subscriber.complete();
      })
      .catch(() => {
        subscriber.error(new Error("FAILED_REFRESH"));
      });
  });
}

const authErrorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors != null) {
    for (const graphQLError of graphQLErrors) {
      switch (graphQLError.extensions.code) {
        case "UNAUTHENTICATED":
          return getNewToken().flatMap(token => {
            const prevContext = operation.getContext();
            operation.setContext({
              ...prevContext,
              headers: {
                ...prevContext.headers,
                authorization: token ? `Bearer ${token}` : undefined,
              },
            });

            return forward(operation);
          });
      }
    }
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError != null && networkError.message === "FAILED_REFRESH") {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href = "/login";
    return;
  }
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: from([errorLink, authErrorLink, authLink.concat(httpLink)]),
  connectToDevTools: true,
});

export default client;
